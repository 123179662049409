const kv = {
    'back': 'Back',
    'invalid-email': 'Invalid email address',
    'invalid-driver-email': 'Invalid driver email address',
    'invalid-phone': 'Invalid phone number',
    'invalid-zipcode': 'Invalid postal code',
    'invalid-address': 'Invalid address ',
    'invalid-name': 'Invalid username ',
    'invalid-driver-phone': 'Invalid driver phone number',
    'invalid-sn-code': 'Invalid social security number',
    'img-upload-error': 'Image upload failed, please try again later.',
    'order-submit-error': 'Your test drive information submission failed, please try again later.',
    'order-submit-succeed': 'Your test drive information was submitted successfully and we will review it as soon as possible!',
    'translations-fetch-error': 'Failed to obtain translation resources.',
    'device-not-found': 'Requested device not found.',
  
    'ctry-name-Afghanistan': 'Afghanistan',
    'ctry-name-Albania': 'Albania',
    'ctry-name-Algeria': 'Algeria',
    'ctry-name-AmericanSamoa': 'American Samoa',
    'ctry-name-Andorra': 'Andorra',
    'ctry-name-Angola': 'Angola',
    'ctry-name-Anguilla': 'Anguilla',
    'ctry-name-AntiguaandBarbuda': 'Antigua and Barbuda',
    'ctry-name-Argentina': 'Argentina',
    'ctry-name-Armenia': 'Armenia',
    'ctry-name-Aruba': 'Aruba',
    'ctry-name-Australia': 'Australia',
    'ctry-name-Austria': 'Austria',
    'ctry-name-Azerbaijan': 'Azerbaijan',
    'ctry-name-Bahamas': 'Bahamas',
    'ctry-name-Bahrain': 'Bahrain',
    'ctry-name-Bangladesh': 'Bangladesh',
    'ctry-name-Barbados': 'Barbados',
    'ctry-name-Belarus': 'Belarus',
    'ctry-name-Belgium': 'Belgium',
    'ctry-name-Belize': 'Belize',
    'ctry-name-Benin': 'Benin',
    'ctry-name-Bermuda': 'Bermuda',
    'ctry-name-Bhutan': 'Bhutan',
    'ctry-name-BoliviaPlurinationalStateof': 'Bolivia Plurinational State of',
    'ctry-name-BosniaandHerzegovina': 'Bosnia and Herzegovina',
    'ctry-name-Botswana': 'Botswana',
    'ctry-name-Brazil': 'Brazil',
    'ctry-name-BritishIndianOceanTerritory': 'British Indian Ocean Territory',
    'ctry-name-Bulgaria': 'Bulgaria',
    'ctry-name-BurkinaFaso': 'Burkina Faso',
    'ctry-name-Burundi': 'Burundi',
    'ctry-name-Cambodia': 'Cambodia',
    'ctry-name-Cameroon': 'Cameroon',
    'ctry-name-Canada': 'Canada',
    'ctry-name-CapeVerde': 'Cape Verde',
    'ctry-name-CaymanIslands': 'Cayman Islands',
    'ctry-name-CentralAfricanRepublic': 'Central African Republic',
    'ctry-name-Chad': 'Chad',
    'ctry-name-Chile': 'Chile',
    'ctry-name-China': 'China',
    'ctry-name-Colombia': 'Colombia',
    'ctry-name-Comoros': 'Comoros',
    'ctry-name-Congo': 'Congo',
    'ctry-name-DemocraticRepublicoftheCongo': 'Democratic Republic of the Congo',
    'ctry-name-CookIslands': 'Cook Islands',
    'ctry-name-CostaRica': 'Costa Rica',
    'ctry-name-CotedIvoire': "Côte d'Ivoire",
    'ctry-name-Croatia': 'Croatia',
    'ctry-name-Cuba': 'Cuba',
    'ctry-name-Curaçao': 'Curaçao',
    'ctry-name-Cyprus': 'Cyprus',
    'ctry-name-CzechRepublic': 'Czech Republic',
    'ctry-name-Denmark': 'Denmark',
    'ctry-name-Djibouti': 'Djibouti',
    'ctry-name-Dominica': 'Dominica',
    'ctry-name-DominicanRepublic': 'Dominican Republic',
    'ctry-name-Ecuador': 'Ecuador',
    'ctry-name-Egypt': 'Egypt',
    'ctry-name-ElSalvador': 'El Salvador',
    'ctry-name-EquatorialGuinea': 'Equatorial Guinea',
    'ctry-name-Eritrea': 'Eritrea',
    'ctry-name-Estonia': 'Estonia',
    'ctry-name-Ethiopia': 'Ethiopia',
    'ctry-name-FalklandIslandsMalvinas': 'Falkland Islands (Malvinas)',
    'ctry-name-FaroeIslands': 'Faroe Islands',
    'ctry-name-Fiji': 'Fiji',
    'ctry-name-Finland': 'Finland',
    'ctry-name-France': 'France',
    'ctry-name-FrenchPolynesia': 'French Polynesia',
    'ctry-name-Gabon': 'Gabon',
    'ctry-name-Gambia': 'Gambia',
    'ctry-name-Georgia': 'Georgia',
    'ctry-name-Germany': 'Germany',
    'ctry-name-Ghana': 'Ghana',
    'ctry-name-Gibraltar': 'Gibraltar',
    'ctry-name-Greece': 'Greece',
    'ctry-name-Greenland': 'Greenland',
    'ctry-name-Grenada': 'Grenada',
    'ctry-name-Guam': 'Guam',
    'ctry-name-Guatemala': 'Guatemala',
    'ctry-name-Guernsey': 'Guernsey',
    'ctry-name-Guinea': 'Guinea',
    'ctry-name-GuineaBissau': 'Guinea-Bissau',
    'ctry-name-Haiti': 'Haiti',
    'ctry-name-Honduras': 'Honduras',
    'ctry-name-HongKong': 'Hong Kong',
    'ctry-name-Hungary': 'Hungary',
    'ctry-name-Iceland': 'Iceland',
    'ctry-name-India': 'India',
    'ctry-name-Indonesia': 'Indonesia',
    'ctry-name-IranIslamicRepublicof': "Iran', Islamic Republic of",
    'ctry-name-Iraq': 'Iraq',
    'ctry-name-Ireland': 'Ireland',
    'ctry-name-IsleofMan': 'Isle of Man',
    'ctry-name-Israel': 'Israel',
    'ctry-name-Italy': 'Italy',
    'ctry-name-Jamaica': 'Jamaica',
    'ctry-name-Japan': 'Japan',
    'ctry-name-Jersey': 'Jersey',
    'ctry-name-Jordan': 'Jordan',
    'ctry-name-Kazakhstan': 'Kazakhstan',
    'ctry-name-Kenya': 'Kenya',
    'ctry-name-Kiribati': 'Kiribati',
    'ctry-name-NorthKorea': 'North Korea',
    'ctry-name-SouthKorea': 'South Korea',
    'ctry-name-Kuwait': 'Kuwait',
    'ctry-name-Kyrgyzstan': 'Kyrgyzstan',
    'ctry-name-LaoPeoplesDemocraticRepublic': "Lao People's Democratic Republic",
    'ctry-name-Latvia': 'Latvia',
    'ctry-name-Lebanon': 'Lebanon',
    'ctry-name-Lesotho': 'Lesotho',
    'ctry-name-Liberia': 'Liberia',
    'ctry-name-Libya': 'Libya',
    'ctry-name-Liechtenstein': 'Liechtenstein',
    'ctry-name-Lithuania': 'Lithuania',
    'ctry-name-Luxembourg': 'Luxembourg',
    'ctry-name-Macao': 'Macao',
    'ctry-name-RepublicofMacedonia': 'Republic of Macedonia',
    'ctry-name-Madagascar': 'Madagascar',
    'ctry-name-Malawi': 'Malawi',
    'ctry-name-Malaysia': 'Malaysia',
    'ctry-name-Maldives': 'Maldives',
    'ctry-name-Mali': 'Mali',
    'ctry-name-Malta': 'Malta',
    'ctry-name-MarshallIslands': 'Marshall Islands',
    'ctry-name-Martinique': 'Martinique',
    'ctry-name-Mauritania': 'Mauritania',
    'ctry-name-Mauritius': 'Mauritius',
    'ctry-name-Mexico': 'Mexico',
    'ctry-name-MicronesiaFederatedStatesof': "Micronesia', Federated States of",
    'ctry-name-RepublicofMoldova': 'Republic of Moldova',
    'ctry-name-Monaco': 'Monaco',
    'ctry-name-Mongolia': 'Mongolia',
    'ctry-name-Montenegro': 'Montenegro',
    'ctry-name-Montserrat': 'Montserrat',
    'ctry-name-Morocco': 'Morocco',
    'ctry-name-Mozambique': 'Mozambique',
    'ctry-name-Myanmar': 'Myanmar',
    'ctry-name-Namibia': 'Namibia',
    'ctry-name-Nauru': 'Nauru',
    'ctry-name-Nepal': 'Nepal',
    'ctry-name-Netherlands': 'Netherlands',
    'ctry-name-NewZealand': 'New Zealand',
    'ctry-name-Nicaragua': 'Nicaragua',
    'ctry-name-Niger': 'Niger',
    'ctry-name-Nigeria': 'Nigeria',
    'ctry-name-Niue': 'Niue',
    'ctry-name-NorfolkIsland': 'Norfolk Island',
    'ctry-name-NorthernMarianaIslands': 'Northern Mariana Islands',
    'ctry-name-Norway': 'Norway',
    'ctry-name-Oman': 'Oman',
    'ctry-name-Pakistan': 'Pakistan',
    'ctry-name-Palau': 'Palau',
    'ctry-name-PalestinianTerritory': 'Palestinian Territory',
    'ctry-name-Panama': 'Panama',
    'ctry-name-PapuaNewGuinea': 'Papua New Guinea',
    'ctry-name-Paraguay': 'Paraguay',
    'ctry-name-Peru': 'Peru',
    'ctry-name-Philippines': 'Philippines',
    'ctry-name-Pitcairn': 'Pitcairn',
    'ctry-name-Poland': 'Poland',
    'ctry-name-Portugal': 'Portugal',
    'ctry-name-PuertoRico': 'Puerto Rico',
    'ctry-name-Qatar': 'Qatar',
    'ctry-name-Romania': 'Romania',
    'ctry-name-Russian': 'Russian',
    'ctry-name-Rwanda': 'Rwanda',
    'ctry-name-SaintKittsandNevis': 'Saint Kitts and Nevis',
    'ctry-name-SaintLucia': 'Saint Lucia',
    'ctry-name-Samoa': 'Samoa',
    'ctry-name-SanMarino': 'San Marino',
    'ctry-name-SaoTomeandPrincipe': 'Sao Tome and Principe',
    'ctry-name-SaudiArabia': 'Saudi Arabia',
    'ctry-name-Senegal': 'Senegal',
    'ctry-name-Serbia': 'Serbia',
    'ctry-name-Seychelles': 'Seychelles',
    'ctry-name-SierraLeone': 'Sierra Leone',
    'ctry-name-Singapore': 'Singapore',
    'ctry-name-SintMaarten': 'Sint Maarten',
    'ctry-name-Slovakia': 'Slovakia',
    'ctry-name-Slovenia': 'Slovenia',
    'ctry-name-SolomonIslands': 'Solomon Islands',
    'ctry-name-Somalia': 'Somalia',
    'ctry-name-SouthAfrica': 'South Africa',
    'ctry-name-SouthSudan': 'South Sudan',
    'ctry-name-Spain': 'Spain',
    'ctry-name-SriLanka': 'Sri Lanka',
    'ctry-name-Sudan': 'Sudan',
    'ctry-name-Suriname': 'Suriname',
    'ctry-name-Swaziland': 'Swaziland',
    'ctry-name-Sweden': 'Sweden',
    'ctry-name-Switzerland': 'Switzerland',
    'ctry-name-Syria': 'Syria',
    'ctry-name-TaiwanProvinceofChina': "Taiwan', Province of China",
    'ctry-name-Tajikistan': 'Tajikistan',
    'ctry-name-Tanzania': 'Tanzania',
    'ctry-name-Thailand': 'Thailand',
    'ctry-name-Togo': 'Togo',
    'ctry-name-Tokelau': 'Tokelau',
    'ctry-name-Tonga': 'Tonga',
    'ctry-name-TrinidadandTobago': 'Trinidad and Tobago',
    'ctry-name-Tunisia': 'Tunisia',
    'ctry-name-Turkey': 'Turkey',
    'ctry-name-Turkmenistan': 'Turkmenistan',
    'ctry-name-TurksandCaicosIslands': 'Turks and Caicos Islands',
    'ctry-name-Tuvalu': 'Tuvalu',
    'ctry-name-Uganda': 'Uganda',
    'ctry-name-Ukraine': 'Ukraine',
    'ctry-name-UnitedArabEmirates': 'United Arab Emirates',
    'ctry-name-UnitedKingdom': 'United Kingdom',
    'ctry-name-UnitedStates': 'United States',
    'ctry-name-Uruguay': 'Uruguay',
    'ctry-name-Uzbekistan': 'Uzbekistan',
    'ctry-name-Vanuatu': 'Vanuatu',
    'ctry-name-VenezuelaBolivarianRepublicof': "Venezuela', Bolivarian Republic of",
    'ctry-name-VietNam': 'Viet Nam',
    'ctry-name-VirginIslands': 'Virgin Islands',
    'ctry-name-Yemen': 'Yemen',
    'ctry-name-Zambia': 'Zambia',
    'ctry-name-Zimbabwe': 'Zimbabwe',
  };
  export { kv };
  
  