import React, { Component } from 'react';
import FooterLogo from '../../../images/gyomusuper/footer.png'

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {specifiedClass} = this.props;
    return (<div className={'tw-w-full tw-bg-[#00A552] tw-text-white tw-px-[20px] tw-py-[15px] ' + specifiedClass} >
        <div className='tw-my-[20px] tw-flex tw-items-center tw-flex-wrap tw-text-[10px]'>
            <div>お問い合わせ</div>
            <div className='tw-mx-[20px]'>|</div>
            <div>会社案内</div>
            <div className='tw-mx-[20px]'>|</div>
            <div>サイトポリシー</div>
            <div className='tw-mx-[20px]'>|</div>
            <div>個人情報の保護に関する基本方針</div>
        </div>

        <div className='tw-mt-[20px] tw-text-[10px]'>
            <div className='tw-text-[12px]'>〒675-0063</div>
            <div>兵庫県加古川市加古川町平野125番1</div>
            <img src={FooterLogo} alt='logo' className='tw-w-[193px]'/>
            <div className='tw-my-[20px] tw-text-[12px] tw-pb-[36px]'>
                <div>©2018-2024</div>
                <div className='tw-ml-6px]'>Gyomu Super All Rights Reserved.</div>
            </div>

        </div>

    </div>);
  }
}

export default Footer;
