import React, { Component } from 'react';
import queryString from "query-string";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input } from 'reactstrap';
import TeslaIcon from '../../icons/TeslaIcon';
import RDSButton from '../../components/common/RDSButton';
import RdsBackIcon from '../../icons/RdsBackIcon';
import Loading from '../../components/common/LoadingIndicator';
import VideoIcon from '../../icons/VideoIcon';
import CameraIcon from '../../icons/CameraIcon';
import * as imgActionCreator from '../../actions/imgActionCreator';
import * as orderActionCreator from '../../actions/orderActionCreator';
import * as translationActionCreator from '../../actions/translationActionCreator';
import * as globalAlertActionCreator from '../../actions/globalAlertActionCreator';
import * as fbEventActionCreator from '../../actions/fbEventActionCreator';
import * as TranslationSelectors from '../../selectors/TranslationSelectors';

import classNames from 'classnames';
import SelfieGif from '../../images/selfie.gif';
import SelfieMask from '../../images/selfieMask.gif';
import CameraMask from '../../images/camera-mask.png';
import RestartIcon from '../../icons/RestartIcon';
import Colors from '../../constants/ColorConstants';
import CountdownButton from '../../components/common/CountdownButton';
import GlobalAlertConstants from '../../constants/GlobalAlertConstants';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import PagePathConstants from '../../constants/router/PagePathConstants';
import { BASE_URL } from '../../constants/api/ApiEndpointConstants';
import StoreStateConstants from '../../constants/store/StoreStateConstants';
import tracker from '../../tracker';
import * as FBUtil from '../../utils/FbUtil';
import { generateRandomString } from '../../utils/RandomGeneratorUtils';
import { sendCustomEvent } from '../../apis/ConfigAPI';
import MobileDetectUtil from '../../utils/MobileDetectUtil';
import CookieUtil from '../../utils/CookieUtil';

class SelfieInfoPage extends Component {
    constructor(props, context) {
        super(props, context);
        let { translations } = props;
        translations = translations && translations['submission_page'];
        this.state = {
            showOnboardingGif: true,
            showLoading: false,
            userSelfPictureOrVideo: '',
            showPortraitVideo: false,

            videoResolutionX: 2160,
            videoResolutionY: 3840,
            videoWidth: 400,
            videoHeight: 300,

            isPlaying: false,
            streamUrl: '',
            selfieVideoTipArr: ['カメラを見て3秒間保持してください', '← 左に顔を向けて3秒間保持してください', '→ 右に顔を向けて3秒間保持してください'],
            activeTipIndex: 0,
            isCountdowning: false,
        };

        this.toggleLoading = this.toggleLoading.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.goBack = this.goBack.bind(this);
        this.togglePortraitVideo = this.togglePortraitVideo.bind(this);
        this.requestAudioAccess = this.requestAudioAccess.bind(this);
        this.playPortraitVideo = this.playPortraitVideo.bind(this);
        this.stopPoraitVideo = this.stopPoraitVideo.bind(this);
        this.restartVideo = this.restartVideo.bind(this);
        this.toggleOnboarding = this.toggleOnboarding.bind(this);
        this.prefillOrderParams = this.prefillOrderParams.bind(this);
        this.checkInfoToSubmit = this.checkInfoToSubmit.bind(this);
        this.sendAddPaymentInfoEvent = this.sendAddPaymentInfoEvent.bind(this);
        this.handleSelectFile = this.handleSelectFile.bind(this);
        this.restartPicture = this.restartPicture.bind(this);
    }
    videoRef = React.createRef();
    video2Ref = React.createRef();
    videoMaskRef = React.createRef();
    countDownRef = React.createRef();
    recorder = null;
    chunks = [];
    stream = null;
    selfieVideoInterval = null;

    componentDidMount() {
        this.prefillOrderParams();
    }

    prefillOrderParams() {
        const orderInfo = LocalStorageUtil.loadOrderDetail();
        this.setState({
            userSelfPictureOrVideo: orderInfo.userSelfPictureOrVideo || '',
            showPortraitVideo: orderInfo.userSelfPictureOrVideo && orderInfo.userSelfPictureOrVideo.trim() !== ''
        });
    }

    toggleOnboarding() {
        this.setState({ showOnboardingGif: !this.state.showOnboardingGif });
    }

    restartVideo() {
        const orderData = LocalStorageUtil.loadOrderDetail();
        tracker.trackRestartTakingSelfieVideo(`${orderData.firstName} ${orderData.lastName}`);
        this.setState({ userSelfPictureOrVideo: '' });
        this.requestAudioAccess();
    }

    restartPicture() {
        const orderData = LocalStorageUtil.loadOrderDetail();
        tracker.trackRestartTakingSelfieVideo(`${orderData.firstName} ${orderData.lastName}`);
        this.setState({ userSelfPictureOrVideo: '', showPortraitVideo: false });
    }

    requestAudioAccess() {
        const that = this;
        const orderData = LocalStorageUtil.loadOrderDetail();
        return navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .then(stream => {
                this.setState({ showPortraitVideo: !this.state.showPortraitVideo });
                this.recorder = new window.MediaRecorder(stream);
                this.stream = stream;
                if (this.videoRef.current) {
                    this.videoRef.current.srcObject = stream;
                    this.videoRef.current.muted = true;
                    this.videoRef.current.play();
                    setTimeout(() => {
                        this.videoMaskRef.current.style.width = this.videoRef.current.getBoundingClientRect().width + 'px';
                        this.videoMaskRef.current.style.height = this.videoRef.current.getBoundingClientRect().height + 'px';
                    }, 1000);
                } else {
                    tracker.trackVideoError(`${orderData.firstName} ${orderData.lastName}`);
                }

                this.recorder.ondataavailable = (e) => {
                    that.chunks.push(e.data);
                };
                this.recorder.onstop = () => {
                    tracker.trackEndingSelfieVideo(`${orderData.firstName} ${orderData.lastName}`);
                    let blob = new Blob(that.chunks, { 'type': 'video/mp4' });
                    that.toggleLoading();
                    const { imgActions } = this.props;
                    imgActions && imgActions.uploadVideoToGetUrl(blob)
                        .then(url => {
                            this.sendAddPaymentInfoEvent();

                            tracker.trackUploadSelfieVideo(`${orderData.firstName} ${orderData.lastName}`);
                            that.toggleLoading();
                            this.setState({ userSelfPictureOrVideo: url }, this.checkInfoToSubmit);
                            this.video2Ref.current.style.display = 'block';
                            this.video2Ref.current.src = url;
                        })
                        .catch((err) => {
                            this.toggleLoading();
                        });
                    that.chunks = [];
                };
            })
            .catch(error => {
                console.error(error)
                console.error('错误！！！')
                const { globalAlertActions } = this.props;
                globalAlertActions && globalAlertActions.dispatchGlobalAlert('device-not-found', 'Requested device not found', GlobalAlertConstants.ERROR);
                this.setState({ showPortraitVideo: false })
            });
    }

    sendAddPaymentInfoEvent() {
        const { fbEventActions, orderActions } = this.props;
        const finallyOrderInfo = LocalStorageUtil.loadOrderDetail();

        const sendEvent = (orderData) => {
            const { email, phone, firstName, lastName, state, zipCode, country } = orderData;
            const eventId = generateRandomString(10);
            const eventParams = {
                content_ids: 'gyomusuper-coupon',
                contents: [{
                    id: 'gyomusuper-coupon-10000',
                    title: 'Gyomusuper-Coupon',
                    quantity: 1,
                }],
                currency: 'JPY',
                value: CookieUtil.loadPaymentAmount()
            };
            sendCustomEvent('AddPaymentInfo');
            FBUtil.trackFBEvent('AddPaymentInfo', eventParams, eventId);
            const userdata = {
                "em": email,
                "ph": phone,
                "fn": firstName,
                "ln": lastName,
                "ct": state && state.trim().replaceAll(' ', ''),
                "st": state && state.trim().replaceAll(' ', ''),
                "zp": zipCode && zipCode.trim().replaceAll(' ', ''),
                "country": country && country.toLowerCase(),
            }
            fbEventActions && fbEventActions.submitFBConversionEvent('AddPaymentInfo', eventParams, ((email && email.trim() !== '') ? userdata : null), eventId, null);
        }

        const orderNo = LocalStorageUtil.loadOrderNumber();
        if (!finallyOrderInfo.email && orderNo && orderNo.trim() !== '') {
            // 调用接口根据订单编号获取订单信息
            orderActions && orderActions.getOrderDetailByOrderNo(orderNo)
            .then((data) => {
                const orderData = JSON.parse(data.data);
                sendEvent(orderData);
            })
            .catch(() => {});
        } else {
            sendEvent(finallyOrderInfo);
        }
    }

    togglePortraitVideo() {
        //打开摄像头，并将数据显示到video标签上
        const orderData = LocalStorageUtil.loadOrderDetail();
        if (!navigator.mediaDevices) {
            console.error('您的浏览器不支持获取用户设备');
            tracker.trackVideoAccessError({ username: `${orderData.firstName} ${orderData.lastName}`, accessError: '!navigator.mediaDevices' });
            return;
        }
        if (!window.MediaRecorder) {
            console.error('您的浏览器不支持录音');
            tracker.trackVideoAccessError({ username: `${orderData.firstName} ${orderData.lastName}`, accessError: '!window.MediaRecorder' });
            return;
        }
        this.requestAudioAccess()
            // .then(() => {
            //     this.setState({ showPortraitVideo: !this.state.showPortraitVideo });
            // })
            // .catch(() => { console.log('错误！！！') });
    }

    toggleVideoRecording() {
        this.setState({ isPlaying: !this.state.isPlaying });
    }

    playPortraitVideo() {
        if (this.recorder) {
            const orderData = LocalStorageUtil.loadOrderDetail();
            tracker.trackTakingSelfieVideo(`${orderData.firstName} ${orderData.lastName}`);
            this.toggleVideoRecording();
            this.recorder.start();
            this.setState({isCountdowning: true});
            let activeTipIndex = 0;
            this.selfieVideoInterval = setInterval(() => {
                activeTipIndex = activeTipIndex + 1;
                this.setState({ activeTipIndex });
            }, 3000);
            return Promise.resolve();
        }
    }

    stopPoraitVideo() {
        if (this.recorder) {
            this.toggleVideoRecording();
            this.recorder.stop();
            this.setState({isCountdowning: false});
        }
        if (this.selfieVideoInterval) {
            window.clearInterval(this.selfieVideoInterval);
        }
    }

    goBack() {
        const { history } = this.props;
        history && history.goBack();
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    checkInfoToSubmit() {
        const { userSelfPictureOrVideo } = this.state;
        const { orderActions, location } = this.props;
        const orderInfo = LocalStorageUtil.loadOrderDetail();
        const queryParams = queryString.parse(location.search);
        let ad = 'test';
        const localAd = LocalStorageUtil.loadFbAdParams();
        if (localAd && localAd.trim() !== '') {
            ad = localAd;
        }
        if (queryParams.ad && queryParams.ad !== '') {
            ad = queryParams.ad;
        }

        LocalStorageUtil.saveOrderDetail({ userSelfPictureOrVideo });
        if (orderInfo) {
            const { firstName, lastName, email, phone, driveEmail, drivePhone, state, city, country, address, zipCode, vehicleModel, identityType, documentFront, insuranceType, snCode, documentBack } = orderInfo;
            let data = {
                firstName,
                lastName,
                email,
                phone,
                driveEmail,
                drivePhone,
                state,
                city,
                country,
                address,
                zipCode,
                vehicleModel,
                identityType,
                documentFront: documentFront && documentFront.replace(BASE_URL, ''),
                userSelfPictureOrVideo: userSelfPictureOrVideo && userSelfPictureOrVideo.replace(BASE_URL, ''),
                id: '',
                orderNu: '',
                ad,
            }
            const orderId = LocalStorageUtil.loadOrderId();
            if (orderId && orderId !== '' && orderId !== 'undefined') {
                data.id = orderId;
            }

            const orderNu = LocalStorageUtil.loadOrderNumber();
            if (orderNu && orderNu !== '' && orderNu !== 'undefined') {
                data.orderNu = orderNu;
            }
            
            if (country === 'US') {
                data['snCode'] = snCode;
            }
            if (data.identityType !== StoreStateConstants.IDENTITY_TYPE_PASSPORT) {
                data['documentBack'] = documentBack && documentBack.replace(BASE_URL, '');
            }
            const queryParams = queryString.parse(location.search);
            if (queryParams.ad && queryParams.ad !== '') {
                data['ad'] = queryParams.ad;
            }
            if (firstName && firstName.trim() !== '' && lastName && lastName.trim() !== '') {
                orderActions && orderActions.updateDriverOrder(data)
                .then(() => {
                    tracker.trackAutoUploadAllUserinfo(`${firstName} ${lastName}`);
                    const orderData = LocalStorageUtil.loadOrderDetail();
                })
                .catch(err => {
                    if (err === 'id duplication') {
                        LocalStorageUtil.clearOrderId();
                        LocalStorageUtil.clearOrderNumber();
                    }
                });
            }
        }
    }

    handleSubmitForm() {
        const { userSelfPictureOrVideo } = this.state;
        const { history, location, orderActions } = this.props;
        
        const queryParams = queryString.parse(location.search);
        let ad = 'test';
        const localAd = LocalStorageUtil.loadFbAdParams();
        if (localAd && localAd.trim() !== '') {
            ad = localAd;
        }
        if (queryParams.ad && queryParams.ad !== '') {
            ad = queryParams.ad;
        }
        let data = {
            userSelfPictureOrVideo,
            ad,
            id: '',
            orderNu: '',
        }
        const orderId = LocalStorageUtil.loadOrderId();
        if (orderId && orderId !== '' && orderId !== 'undefined') {
            data.id = orderId;
        }

        const orderNu = LocalStorageUtil.loadOrderNumber();
        if (orderNu && orderNu !== '' && orderNu !== 'undefined') {
            data.orderNu = orderNu;
        }

        LocalStorageUtil.saveOrderDetail({ userSelfPictureOrVideo });

        this.toggleLoading();
        orderActions.updateDriverOrder(data)
            .then(order => {
                this.toggleLoading();
                order.id && order.id !== 0 && LocalStorageUtil.saveOrderId(order.id);
                order.orderNu && order.orderNu !== 0 && LocalStorageUtil.saveOrderNumber(order.orderNu);
                history && history.push({ pathname: PagePathConstants.ORDER_CONFIRM, search: location.search });
            })
            .catch(err => {
                if (err === 'id duplication') {
                    LocalStorageUtil.clearOrderId();
                    LocalStorageUtil.clearOrderNumber();
                }
                this.toggleLoading();
            })

    }

    handleSelectFile(e) {
        e.preventDefault();
        e && e.stopPropagation();

        const { imgActions } = this.props;
        const _this = this;
        [...e.target.files].forEach((fileInfo, index) => {
            if (index > 0) return;
            let reads = new FileReader();
            reads.readAsDataURL(fileInfo);
            reads.onload = function (e) {
                const imgUrl = this.result;
                _this.toggleLoading();
                imgActions && imgActions.uploadImgToGetUrl(imgUrl)
                    .then(url => {
                        _this.toggleLoading();
                        _this.setState({ userSelfPictureOrVideo: url, showPortraitVideo: true }, _this.checkInfoToSubmit);
                        e.target.value = '';
                    })
                    .catch((err) => {
                        _this.toggleLoading();
                        e.target.value = '';
                    });
            };
        });
    }

    render() {
        const { showLoading, userSelfPictureOrVideo, showPortraitVideo, videoHeight, videoWidth, showOnboardingGif, selfieVideoTipArr, activeTipIndex, isCountdowning } = this.state;
        const disabledSubmitBtn = (userSelfPictureOrVideo.trim() === '')
        let translations = this.props.translations && this.props.translations['submission_page'];
        const isAndroid = MobileDetectUtil.judgeClient() === 'Android';
        return (
            <div className='tw-w-full tw-h-full gotham-font tw-text-[#393c41] '>
                {showLoading && <Loading />}
                <div className='tw-w-full tw-h-full phone:tw-h-full tw-pb-[200px] tw-overflow-scroll'>
                    <div className='tw-z-[100] tw-w-[660px] tw-mx-auto phone:tw-w-full tw-h-[80px] tw-flex tw-justify-between'>
                        <div className='tw-w-1/3 tw-h-full tw-flex tw-items-center phone:tw-pl-[30px]'><RdsBackIcon color='#00A552' onClick={this.goBack} /></div>
                        <TeslaIcon size={150} color='#00A552' />
                        <div className='tw-w-1/3'></div>
                    </div>

                    {showOnboardingGif ? <div className='tw-w-full tw-text-center tw-flex tw-flex-col tw-items-center'>
                        <div className='tw-text-[32px] phone:tw-text-[26px] tw-font-bold'>カメラを準備してください</div>
                        <div className='tw-text-[20px] phone:tw-text-[16px] tw-mt-[20px]'>明るい部屋で写真を撮りましょう</div>
                        <div className='tw-text-[18px] phone:tw-text-[16px] tw-mt-[20px] tw-mx-[30px]'>ディープフェイクに対抗する高度な生存検出を使用することで、セキュリティを優先します。</div>
                        <div className='tw-mt-[50px] phone:tw-mt-0 tw-w-[660px] phone:tw-w-[calc(100%-40px)] tw-h-[495px] phone:tw-h-[400px]' style={{ backgroundImage: `url(${SelfieGif})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                        <div className='tw-w-full tw-flex tw-justify-center tw-items-center tw-mt-[30px] tw-text-white tw-mt-[10px]'>
                            <RDSButton
                                rectangle
                                className="tw-w-[80%] tw-rounded-[6px]"
                                color="primary"
                                onClick={this.toggleOnboarding}>
                                <span>次のステップ</span>
                            </RDSButton>
                        </div>
                    </div> :
                        <div className='tw-w-[660px] tw-mx-auto phone:tw-px-[20px] phone:tw-w-full phone:tw-mt-[0px]'>
                            <div className='tw-mt-0'>
                                <div className='tw-w-full '>
                                    <div className='tw-text-[#5c5e62] tw-font-[500] tw-text-[28px] phone:tw-text-[22px]'>{isAndroid ? (translations && translations.title_UploadYS) : '自撮りを撮る'}</div>
                                    <div className='tw-flex tw-justify-between tw-items-start tw-mb-[30px] tw-mt-[15px]'>
                                        <div className='tw-w-full tw-h-[300px] phone:tw-h-auto phone:tw-w-full tw-relative'>
                                            {userSelfPictureOrVideo === '' && !showPortraitVideo && <React.Fragment>
                                                <svg className='tw-w-full tw-h-[300px] phone:tw-h-auto tw-flex tw-justify-center tw-items-center ' viewBox="0 0 256 219" fill="none">
                                                    <g opacity="0.5">
                                                        <path d="M131.122 22H125.272C123.826 22 122.381 22.0615 120.94 22.1843C92.2256 24.6309 70.888 50.4149 73.2809 79.7744L75.7287 109.808C78.0027 137.71 100.814 159.171 128.197 159.171C155.58 159.171 178.391 137.71 180.665 109.808L183.113 79.7744C183.233 78.3009 183.293 76.8229 183.293 75.3444C183.293 45.8831 159.935 22 131.122 22Z" fill="#E3E8EE" />
                                                        <path d="M127.496 170C148.219 170 159.833 161.145 181.918 161.145C238.391 161.145 238.391 217.511 238.391 217.511V219H224.617H31.7744H18V217.511C18 217.511 18 161.145 74.4732 161.145C100.971 161.145 106.772 170 127.496 170Z" fill="#E3E8EE" />
                                                    </g>
                                                    <path d="M226.135 2.00008H243.921C249.443 2.00008 253.921 6.47723 253.921 12.0001V33.1546" stroke="#6C8EEF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M29.7858 189.966L12.0004 189.966C6.47756 189.966 2.00041 185.488 2.00041 179.966L2.00042 158.811" stroke="#6C8EEF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M253.921 158.811L253.921 179.966C253.921 185.488 249.444 189.966 243.921 189.966L226.136 189.966" stroke="#6C8EEF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M2 33.1545L2 12C2 6.47715 6.47716 2 12 2L29.7854 2.00001" stroke="#6C8EEF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                {isAndroid ? <div className='tw-absolute tw-left-0 tw-bottom-0 tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-z-[100]'>
                                                    <Input className='!tw-border-none tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-[300px] tw-opacity-0 tw-z-[101]' type='file' onChange={this.handleSelectFile} accept="image/*" />
                                                    <CameraIcon size="30" color='rgb(92, 94, 98)' />
                                                    <span className='tw-text-[14px] tw-font-bold'>クリックして自撮りポートレートをアップロード</span>
                                                </div> : <div className='tw-absolute tw-left-0 tw-bottom-0 tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-z-[100]' onClick={this.requestAudioAccess}>
                                                    <VideoIcon size="30" color='rgb(92, 94, 98)' />
                                                    <span className='tw-text-[14px] tw-font-bold'>{translations && translations.video_tip}</span>
                                                </div>}
                                            </React.Fragment>}
                                            {showPortraitVideo && <div className={classNames('tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-relative')}>
                                                {userSelfPictureOrVideo === '' && showPortraitVideo && <video id="video" width={videoWidth} height={videoHeight} ref={this.videoRef} playsInline="false" webkit-playsinline="false"></video>}
                                                {userSelfPictureOrVideo === '' && showPortraitVideo && <div ref={this.videoMaskRef} className={`tw-absolute tw-left-0 tw-top-0`} style={{ backgroundImage: `url(${CameraMask})`, backgroundSize: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>}
                                                {/* {userSelfPictureOrVideo === '' && showPortraitVideo && !showLoading && <div className={`tw-w-[410px] tw-h-[310px] phone:!tw-w-[calc(100%+10px)] phone:!tw-h-[calc(100%+10px)] tw-absolute tw-left-[50%] tw-top-[50%] tw-ml-[-205px] tw-mt-[-155px] phone:!tw-left-[-5px] phone:!tw-top-[-5px] phone:!tw-ml-0 phone:!tw-mt-0 tw-z-[10000]`} style={{ backgroundImage: `url(${SelfieMask})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>} */}
                                                {userSelfPictureOrVideo === '' && showPortraitVideo && <div className='tw-w-full tw-absolute tw-left-0 tw-bottom-[-50px] tw-flex tw-justify-center'>
                                                    <CountdownButton text={"始める"} startHandler={this.playPortraitVideo} stopHandler={this.stopPoraitVideo}></CountdownButton>
                                                </div>}

                                                {userSelfPictureOrVideo === '' && showPortraitVideo && <div className="tw-mt-[10px] tw-flex tw-flex-col tw-justify-center tw-items-center">
                                                    <div className='tw-w-auto tw-text-center tw-text-[14px] tw-font-bold tw-px-[20px] tw-py-[4px] tw-rounded-[30px] tw-bg-[#ebeef1]'>顔をカメラの枠内に収めてください</div>
                                                    {isCountdowning && selfieVideoTipArr.map((item, index) => {
                                                        return (<div key={item} className={classNames('tw-w-auto tw-text-center tw-text-[14px] tw-mt-[10px] tw-font-bold tw-px-[20px] tw-py-[4px] tw-rounded-[30px] tw-bg-[#ebeef1]', { 'hs-payment-loading-text': index === activeTipIndex }, { 'tw-hidden': index !== activeTipIndex })}>{selfieVideoTipArr[index]}</div>)
                                                    })}
                                                </div>}
                                                {userSelfPictureOrVideo !== '' && <div className='tw-w-full tw-h-full tw-relative tw-flex tw-justify-center tw-items-center'>
                                                    {isAndroid ? <img src={userSelfPictureOrVideo} alt='selfie' className='tw-w-full tw-max-h-[350px] tw-border' /> : <video id="video2" width={videoWidth} height={videoHeight} ref={this.video2Ref} src={userSelfPictureOrVideo} style={{ display: userSelfPictureOrVideo.trim() !== '' ? 'block' : 'none' }} type="video/mp4" controls></video>}
                                                    <div className='tw-w-full tw-absolute tw-left-0 tw-bottom-[-50px] tw-flex tw-justify-center'>
                                                        {isAndroid ? <RestartIcon size={30} color={Colors.gray.dark} onClick={this.restartPicture} /> : <RestartIcon size={30} color={Colors.gray.dark} onClick={this.restartVideo} />}
                                                    </div>
                                                </div>}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                {userSelfPictureOrVideo !== '' && <div className='tw-w-full tw-flex tw-justify-center tw-items-center tw-mt-[80px] tw-text-white tw-mt-[50px]'>
                                    <RDSButton
                                        rectangle
                                        className="tw-w-[80%] tw-rounded-[6px]"
                                        color="primary"
                                        onClick={this.handleSubmitForm}
                                        disabled={disabledSubmitBtn}>
                                        <span>次のステップ</span>
                                    </RDSButton>
                                </div>}
                            </div>
                        </div>}
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        translations: TranslationSelectors.selectTranslations(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        imgActions: bindActionCreators(imgActionCreator, dispatch),
        orderActions: bindActionCreators(orderActionCreator, dispatch),
        translationActions: bindActionCreators(translationActionCreator, dispatch),
        globalAlertActions: bindActionCreators(globalAlertActionCreator, dispatch),
        fbEventActions: bindActionCreators(fbEventActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelfieInfoPage);
