import React, { Component } from 'react';
import {
  Switch,
  // HashRouter,
  Route,
  Router
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PagePathConstants from './constants/router/PagePathConstants';
import AdvertisingPage from './pages/AdvertisingPage';
import TeslaPage from './pages/TeslaPage';
import SuccessfullyPage from './pages/SuccessfullyPage';
import VehicleModelSelectorPage from './pages/tesla_order/VehicleModelSelectorPage';
import CustomInfoPage from './pages/tesla_order/CustomInfoPage';
import DocumentInfoPage from './pages/tesla_order/DocumentInfoPage';
import SelfieInfoPage from './pages/tesla_order/SelfieInfoPage';
import OrderConfirmPage from './pages/tesla_order/OrderConfirmPage';
import GlobalAlert from './components/common/GlobalAlert';
import * as translationActionCreator from './actions/translationActionCreator';
import { fetchPixelId } from './apis/ConfigAPI';
import './App.css';
import { setAPIBaseUrl } from './apis/API';
import LocalStorageUtil from './utils/LocalStorageUtil';
import { parseQuerystring } from './utils/Request';
import Loading from './components/common/LoadingIndicator';
import CookieUtil from './utils/CookieUtil';


const browserHistory = createBrowserHistory();
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
    this.toggleLoading = this.toggleLoading.bind(this);
    this.fetchWebsiteBaseConfig = this.fetchWebsiteBaseConfig.bind(this);
  }

  componentDidMount() {
    this.fetchWebsiteBaseConfig();
    // 设置ad 参数
    const url = window.location.href;
    if (url.indexOf('?') >= 0) {
      const queryParams = parseQuerystring(url.substring(url.indexOf('?')));
      if (queryParams.ad && queryParams.ad.trim() !== '') {
        LocalStorageUtil.saveFbAdParams(queryParams.ad);
      }
      if (queryParams.fbclid) {
        LocalStorageUtil.saveFbclid(queryParams.fbclid);
        LocalStorageUtil.saveEntryTimestamp();
      }
    }


    console.log('entry :' + window.location.protocol + "//" + window.location.host);
    if (window.location.host.indexOf('localhost') < 0) {
      const baseUrl = window.location.protocol + "//" + window.location.host;
      console.log('baseUrl--------------: ' + baseUrl);
      setAPIBaseUrl(baseUrl);
    }
    this.fetchTranslationsResources();
  }

  fetchWebsiteBaseConfig() {
    this.toggleLoading();
    fetchPixelId()
      .then(res => {

        if (res.data && res.data.data && res.data.data.pixel && res.data.data.pixel !== '') {
          let pixelId = res.data.data.pixel;
          let fbqToken = res.data.data.fbqToken;
          let tgid = res.data.data.tgid;
          let head = document.querySelector('head');
          let script = document.createElement('script');
          script.type = "text/javascript";
          script.innerHTML = `!function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
              n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
            n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
          }(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${pixelId}');
          fbq('track', 'PageView');`

          head.appendChild(script);

          let noscript = document.createElement('noscript');
          noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1" />`;
          head.appendChild(noscript);

          setTimeout(() => {
            CookieUtil.savePixelID(pixelId);
            CookieUtil.saveFBQToken(fbqToken);
            CookieUtil.saveTelegramId(tgid);
          }, 2000);
        }

        if (res.data && res.data.data && res.data.data.amount) {
          setTimeout(() => {
            CookieUtil.savePaymentAmount(res.data.data.amount);
            CookieUtil.savePaymentAmountFast(res.data.data.amount_fast);
          }, 2000);
        }

        // Google Analytics
        if (res.data && res.data.data && res.data.data.analytics && res.data.data.analytics !== '') {
          console.log('这里~~~~~~~~~~~~~')
          let analyticsId = res.data.data.analytics;
          let head = document.querySelector('head');
          let script = document.createElement('script');
          script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
          script.async = true;
          head.appendChild(script);

          let script2 = document.createElement('script');
          script2.type = "text/javascript";
          script2.innerHTML = `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${analyticsId}');`

          head.appendChild(script2);
        }
        this.toggleLoading();
      })
      .catch(this.toggleLoading);
  }

  fetchTranslationsResources() {
    const { translationActions } = this.props;
    translationActions && translationActions.fetchTranslations('submission_page')
      .then(() => { }).catch(() => { })
  }

  toggleLoading() {
    this.setState({ isLoading: !this.state.isLoading });
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) return <Loading />;
    return (
      <Router history={browserHistory}>
        <React.Fragment>
          <GlobalAlert />
          <Switch>
            <Route path={PagePathConstants.ORDER_CONFIRM} component={OrderConfirmPage} />
            {/* <Route path={PagePathConstants.SELFIE_INFO} component={SelfieInfoPage} />
            <Route path={PagePathConstants.DOCUMENT_INFO} component={DocumentInfoPage} /> */}
            <Route path={PagePathConstants.CUSTOM_INFO} component={CustomInfoPage} />
            <Route path={PagePathConstants.VEHICLE_MODEL} component={VehicleModelSelectorPage} />
            <Route path={PagePathConstants.TESLA} component={TeslaPage} />
            <Route path={PagePathConstants.SUCCESS_CALLBACK} component={SuccessfullyPage} />
            <Route path={PagePathConstants.SUBMISSION_CALLBACK} component={SuccessfullyPage} />
            <Route path={PagePathConstants.ROOT_PAGE} component={AdvertisingPage} />
          </Switch>
        </React.Fragment>
      </Router>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    translationActions: bindActionCreators(translationActionCreator, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(App);